// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.settings-body {
    padding: 0.25em;
}

table.settings-table {
    width: 100%;
}

table.settings-table td select {
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./plugins/style/Settings.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;AACf","sourcesContent":["div.settings-body {\n    padding: 0.25em;\n}\n\ntable.settings-table {\n    width: 100%;\n}\n\ntable.settings-table td select {\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
