// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.geomdigitizer-output-window-body {
    width: 100%;
    height: 100%;
    position: relative;
}

div.geomdigitizer-output-window-body > iframe {
    border: 0;
    width: 100%;
    height: 100%;
}

span.geomdigitizer-output-window-wait {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 0.5em;
    padding: 0.5em;
    display: flex;
    align-items: center;
}

span.geomdigitizer-output-window-wait > div.spinner {
    width: 1.25em;
    height: 1.25em;
    margin-right: 0.5em;
}
`, "",{"version":3,"sources":["webpack://./plugins/style/GeometryDigitizer.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,SAAS;IACT,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,2CAA2C;IAC3C,oBAAoB;IACpB,cAAc;IACd,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,cAAc;IACd,mBAAmB;AACvB","sourcesContent":["div.geomdigitizer-output-window-body {\n    width: 100%;\n    height: 100%;\n    position: relative;\n}\n\ndiv.geomdigitizer-output-window-body > iframe {\n    border: 0;\n    width: 100%;\n    height: 100%;\n}\n\nspan.geomdigitizer-output-window-wait {\n    position: absolute;\n    top: 0;\n    left: 0;\n    background-color: rgba(255, 255, 255, 0.75);\n    border-radius: 0.5em;\n    padding: 0.5em;\n    display: flex;\n    align-items: center;\n}\n\nspan.geomdigitizer-output-window-wait > div.spinner {\n    width: 1.25em;\n    height: 1.25em;\n    margin-right: 0.5em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
