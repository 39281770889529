// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.navbar {
    display: inline-flex;
    margin-right: 1em;
}

div.navbar > select {
    margin-left: 0.25em;
}

div.navbar > span.navbar-dots {
    padding: 0 0.25em;
}
`, "",{"version":3,"sources":["webpack://./qwc2/components/widgets/style/NavBar.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":["div.navbar {\n    display: inline-flex;\n    margin-right: 1em;\n}\n\ndiv.navbar > select {\n    margin-left: 0.25em;\n}\n\ndiv.navbar > span.navbar-dots {\n    padding: 0 0.25em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
