// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.panoramax-body {
    height: calc(100% + 0.5em);
    position: relative;
    display: flex;
    flex-direction: column;
    margin: -0.25em;
}

div.panoramax-body .gvs-psv-tour-arrows{
    all: unset;
}

div.panoramax-body .gvs-btn:focus{
    outline: none;
}
`, "",{"version":3,"sources":["webpack://./plugins/style/Panoramax.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,aAAa;AACjB","sourcesContent":["div.panoramax-body {\n    height: calc(100% + 0.5em);\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    margin: -0.25em;\n}\n\ndiv.panoramax-body .gvs-psv-tour-arrows{\n    all: unset;\n}\n\ndiv.panoramax-body .gvs-btn:focus{\n    outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
