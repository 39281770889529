// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.numeric-input-widget-body > table {
    width: 100%;
}

div.numeric-input-widget-body > table input {
    width: 100%;
}

div.numeric-input-widget-body > table tr:hover {
    background-color: var(--list-item-bg-color-hover);
}`, "",{"version":3,"sources":["webpack://./components/style/NumericInputWindow.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,iDAAiD;AACrD","sourcesContent":["div.numeric-input-widget-body > table {\n    width: 100%;\n}\n\ndiv.numeric-input-widget-body > table input {\n    width: 100%;\n}\n\ndiv.numeric-input-widget-body > table tr:hover {\n    background-color: var(--list-item-bg-color-hover);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
