// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div#ScaleBar {
    position: fixed;
    z-index: 101;
    bottom: 5px;
    left: 5px;
}

div.ol-scale-line {
    position: relative!important;
    background-color: transparent;
    left: 0;
    bottom: 0;
}

div.ol-scale-line > div.ol-scale-line-inner {
    color: var(--panel-text-color);
    border-color: var(--panel-text-color);
}
`, "",{"version":3,"sources":["webpack://./plugins/map/style/ScaleBarSupport.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,WAAW;IACX,SAAS;AACb;;AAEA;IACI,4BAA4B;IAC5B,6BAA6B;IAC7B,OAAO;IACP,SAAS;AACb;;AAEA;IACI,8BAA8B;IAC9B,qCAAqC;AACzC","sourcesContent":["div#ScaleBar {\n    position: fixed;\n    z-index: 101;\n    bottom: 5px;\n    left: 5px;\n}\n\ndiv.ol-scale-line {\n    position: relative!important;\n    background-color: transparent;\n    left: 0;\n    bottom: 0;\n}\n\ndiv.ol-scale-line > div.ol-scale-line-inner {\n    color: var(--panel-text-color);\n    border-color: var(--panel-text-color);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
