// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.View3DSwitcher {
    position: absolute;
    height: 3em;
    width: 3em;
}

div.View3DSwitcher > button {
    bottom: 0;
    right: 0;
    transition: right 0.25s;
}

div.View3DSwitcher.view3d-switcher-expanded {
    width: 11em;
}

div.View3DSwitcher > button:nth-child(1) {
    z-index: 4;
}

div.View3DSwitcher.view3d-switcher-expanded > button:nth-child(2) {
    right: 4em;
}

div.View3DSwitcher.view3d-switcher-expanded > button:nth-child(3) {
    right: 8em;
}`, "",{"version":3,"sources":["webpack://./components/map3d/style/View3DSwitcher.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,UAAU;AACd;;AAEA;IACI,SAAS;IACT,QAAQ;IACR,uBAAuB;AAC3B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd","sourcesContent":["div.View3DSwitcher {\n    position: absolute;\n    height: 3em;\n    width: 3em;\n}\n\ndiv.View3DSwitcher > button {\n    bottom: 0;\n    right: 0;\n    transition: right 0.25s;\n}\n\ndiv.View3DSwitcher.view3d-switcher-expanded {\n    width: 11em;\n}\n\ndiv.View3DSwitcher > button:nth-child(1) {\n    z-index: 4;\n}\n\ndiv.View3DSwitcher.view3d-switcher-expanded > button:nth-child(2) {\n    right: 4em;\n}\n\ndiv.View3DSwitcher.view3d-switcher-expanded > button:nth-child(3) {\n    right: 8em;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
