// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.FileSelector {
    display: inline-flex;
    position: relative;
    cursor: pointer;
    overflow-x: hidden;
}

div.FileSelector > input[type=text] {
    flex: 1 1 auto;
}

div.FileSelector > input[type=file] {
    display: none;
}
`, "",{"version":3,"sources":["webpack://./components/widgets/style/FileSelector.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,kBAAkB;IAClB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;AACjB","sourcesContent":["div.FileSelector {\n    display: inline-flex;\n    position: relative;\n    cursor: pointer;\n    overflow-x: hidden;\n}\n\ndiv.FileSelector > input[type=text] {\n    flex: 1 1 auto;\n}\n\ndiv.FileSelector > input[type=file] {\n    display: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
