// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#Share div.social-links,
#Share div.share-link,
#Share div.qr-code {
    padding: 0.5em 1em;
}

#Share div.SocialMediaShareButton {
    cursor: pointer;
}

#Share div.share-option-pin {
    display: flex;
    margin: 0.25em;
    align-items: center;
    padding: 0.5em 0 1em 0;
    border-bottom: 1px solid var(--border-color);
}

#Share div.share-option-pin > span:first-child {
    flex: 0 0 auto;
    padding-right: 0.5em;
}

#Share div.share-option-pin > span:last-child {
    flex: 1 1 auto;
}

#Share div.share-reload-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1em;
}

#Share div.share-group-restriction {
    margin-top: 0.5em;
}

div.share-validity {
    padding: 1em;
    font-weight: bold;
}

#Share div.qr-code h4 {
    display: inline-block;
}

button.qr-code-copy-button {
    margin-left: 0.5em;
    font-size: 1rem;
    border: none;
    background-color: transparent;
    border: 1px solid transparent;
}

button.qr-code-copy-button:hover {
    border: 1px solid var(--border-color);
}`, "",{"version":3,"sources":["webpack://./plugins/style/Share.css"],"names":[],"mappings":"AAAA;;;IAGI,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,cAAc;IACd,mBAAmB;IACnB,sBAAsB;IACtB,4CAA4C;AAChD;;AAEA;IACI,cAAc;IACd,oBAAoB;AACxB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,6BAA6B;IAC7B,6BAA6B;AACjC;;AAEA;IACI,qCAAqC;AACzC","sourcesContent":["#Share div.social-links,\n#Share div.share-link,\n#Share div.qr-code {\n    padding: 0.5em 1em;\n}\n\n#Share div.SocialMediaShareButton {\n    cursor: pointer;\n}\n\n#Share div.share-option-pin {\n    display: flex;\n    margin: 0.25em;\n    align-items: center;\n    padding: 0.5em 0 1em 0;\n    border-bottom: 1px solid var(--border-color);\n}\n\n#Share div.share-option-pin > span:first-child {\n    flex: 0 0 auto;\n    padding-right: 0.5em;\n}\n\n#Share div.share-option-pin > span:last-child {\n    flex: 1 1 auto;\n}\n\n#Share div.share-reload-overlay {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n    padding: 1em;\n}\n\n#Share div.share-group-restriction {\n    margin-top: 0.5em;\n}\n\ndiv.share-validity {\n    padding: 1em;\n    font-weight: bold;\n}\n\n#Share div.qr-code h4 {\n    display: inline-block;\n}\n\nbutton.qr-code-copy-button {\n    margin-left: 0.5em;\n    font-size: 1rem;\n    border: none;\n    background-color: transparent;\n    border: 1px solid transparent;\n}\n\nbutton.qr-code-copy-button:hover {\n    border: 1px solid var(--border-color);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
