// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.search-widget-container {
    position: relative;
    width: 100%;
}

div.search-widget-container > div.input-container {
    width: 100%;
}
div.search-widget-container > div.input-container > div.spinner {
    width: 2em;
    height: 2em;
}

div.search-widget-results {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    border: 1px solid var(--border-color);
    background-color: var(--list-bg-color);
    max-height: 10em;
    overflow-y: auto;
    z-index: 1;
    box-shadow: 0px 2px 4px rgba(136, 136, 136, 0.5);
}

div.search-widget-results-group-title {
    background-color: var(--list-section-bg-color);
    color: var(--list-section-text-color);
    font-weight: bold;;
}

div.search-widget-results-group-item:hover {
    background-color: var(--list-item-bg-color-hover);
    color: var(--list-item-text-color-hover);
}

div.search-widget-results-group div {
    padding: 0.25em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
`, "",{"version":3,"sources":["webpack://./qwc2/components/widgets/style/SearchWidget.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,WAAW;AACf;AACA;IACI,UAAU;IACV,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,OAAO;IACP,QAAQ;IACR,SAAS;IACT,qCAAqC;IACrC,sCAAsC;IACtC,gBAAgB;IAChB,gBAAgB;IAChB,UAAU;IACV,gDAAgD;AACpD;;AAEA;IACI,8CAA8C;IAC9C,qCAAqC;IACrC,iBAAiB;AACrB;;AAEA;IACI,iDAAiD;IACjD,wCAAwC;AAC5C;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,gBAAgB;IAChB,uBAAuB;AAC3B","sourcesContent":["div.search-widget-container {\n    position: relative;\n    width: 100%;\n}\n\ndiv.search-widget-container > div.input-container {\n    width: 100%;\n}\ndiv.search-widget-container > div.input-container > div.spinner {\n    width: 2em;\n    height: 2em;\n}\n\ndiv.search-widget-results {\n    position: absolute;\n    left: 0;\n    right: 0;\n    top: 100%;\n    border: 1px solid var(--border-color);\n    background-color: var(--list-bg-color);\n    max-height: 10em;\n    overflow-y: auto;\n    z-index: 1;\n    box-shadow: 0px 2px 4px rgba(136, 136, 136, 0.5);\n}\n\ndiv.search-widget-results-group-title {\n    background-color: var(--list-section-bg-color);\n    color: var(--list-section-text-color);\n    font-weight: bold;;\n}\n\ndiv.search-widget-results-group-item:hover {\n    background-color: var(--list-item-bg-color-hover);\n    color: var(--list-item-text-color-hover);\n}\n\ndiv.search-widget-results-group div {\n    padding: 0.25em;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
