// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.snapping-toolbar-container {
    position: absolute;
    bottom: 2.25em;
    left: 0;
    right: 0;
    text-align: center;
    pointer-events: none;
    z-index: 3;
}

div.snapping-toolbar-container > div {
    display: inline-flex;
    align-items: center;
    line-height: 1.5em;
    position: relative;
    max-width: 95%;
    pointer-events: all;
    padding: 0.25em 0.5em;
    background-color: var(--container-bg-color);
    box-shadow: 0px -2px 4px rgba(136, 136, 136, 0.5);
    border-bottom: 1px solid rgba(136, 136, 136, 0.5);
}

div.snapping-toolbar-container div.spinner {
    width: 1.5em;
    height: 1.5em;
}


div.snapping-toolbar-inactive {
    color: var(--text-color-disabled);
    font-style: italic;
}
`, "",{"version":3,"sources":["webpack://./qwc2/plugins/map/style/SnappingSupport.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,cAAc;IACd,OAAO;IACP,QAAQ;IACR,kBAAkB;IAClB,oBAAoB;IACpB,UAAU;AACd;;AAEA;IACI,oBAAoB;IACpB,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;IAClB,cAAc;IACd,mBAAmB;IACnB,qBAAqB;IACrB,2CAA2C;IAC3C,iDAAiD;IACjD,iDAAiD;AACrD;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;;AAGA;IACI,iCAAiC;IACjC,kBAAkB;AACtB","sourcesContent":["div.snapping-toolbar-container {\n    position: absolute;\n    bottom: 2.25em;\n    left: 0;\n    right: 0;\n    text-align: center;\n    pointer-events: none;\n    z-index: 3;\n}\n\ndiv.snapping-toolbar-container > div {\n    display: inline-flex;\n    align-items: center;\n    line-height: 1.5em;\n    position: relative;\n    max-width: 95%;\n    pointer-events: all;\n    padding: 0.25em 0.5em;\n    background-color: var(--container-bg-color);\n    box-shadow: 0px -2px 4px rgba(136, 136, 136, 0.5);\n    border-bottom: 1px solid rgba(136, 136, 136, 0.5);\n}\n\ndiv.snapping-toolbar-container div.spinner {\n    width: 1.5em;\n    height: 1.5em;\n}\n\n\ndiv.snapping-toolbar-inactive {\n    color: var(--text-color-disabled);\n    font-style: italic;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
