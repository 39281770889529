// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.scratch-drawing-taskbar-body {
    display: flex;
    align-items: center;
}

div.scratch-drawing-taskbar-body span {
    margin: 0 0.5em;
}
`, "",{"version":3,"sources":["webpack://./plugins/style/ScratchDrawing.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB","sourcesContent":["div.scratch-drawing-taskbar-body {\n    display: flex;\n    align-items: center;\n}\n\ndiv.scratch-drawing-taskbar-body span {\n    margin: 0 0.5em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
