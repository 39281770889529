// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.theme-switcher-filter {
    flex: 100 1 auto;
    max-width: 15em;
    font-weight: normal;
    color: var(--text-color);
    margin: 0 0.5em;
}
`, "",{"version":3,"sources":["webpack://./plugins/style/ThemeSwitcher.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,eAAe;IACf,mBAAmB;IACnB,wBAAwB;IACxB,eAAe;AACnB","sourcesContent":["div.theme-switcher-filter {\n    flex: 100 1 auto;\n    max-width: 15em;\n    font-weight: normal;\n    color: var(--text-color);\n    margin: 0 0.5em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
