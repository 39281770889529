// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.CookiePopup {
    z-index: 1000;
    position: fixed;
    bottom: 0;
    left: 33%;
    right: 33%;
    background-color: var(--container-bg-color);
    border: 1px solid var(--border-color);
    padding: 1em;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.75);
}

div.cookie-popup-buttonbar {
    text-align: center;
}

div.CookiePopup button {
    margin-top: 0.5em;
    padding: 0.5em;
    background-color: var(--titlebar-bg-color);
    color: var(--titlebar-text-color);
    font-weight: bold;
    border: 1px solid var(--border-color);
}
`, "",{"version":3,"sources":["webpack://./plugins/style/CookiePopup.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,SAAS;IACT,SAAS;IACT,UAAU;IACV,2CAA2C;IAC3C,qCAAqC;IACrC,YAAY;IACZ,2CAA2C;AAC/C;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,cAAc;IACd,0CAA0C;IAC1C,iCAAiC;IACjC,iBAAiB;IACjB,qCAAqC;AACzC","sourcesContent":["div.CookiePopup {\n    z-index: 1000;\n    position: fixed;\n    bottom: 0;\n    left: 33%;\n    right: 33%;\n    background-color: var(--container-bg-color);\n    border: 1px solid var(--border-color);\n    padding: 1em;\n    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.75);\n}\n\ndiv.cookie-popup-buttonbar {\n    text-align: center;\n}\n\ndiv.CookiePopup button {\n    margin-top: 0.5em;\n    padding: 0.5em;\n    background-color: var(--titlebar-bg-color);\n    color: var(--titlebar-text-color);\n    font-weight: bold;\n    border: 1px solid var(--border-color);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
