// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.layer-catalog-widget {
    display: flex;
    flex-direction: column;
}

div.layer-catalog-widget-filter {
    flex: 0 0 auto;
    margin-bottom: -1px;
}

div.layer-catalog-widget-body {
    padding: 0.25em;
    border: 1px solid var(--border-color);
    background-color: var(--list-bg-color);
    overflow-y: auto;
    margin-bottom: 0.5em;
    flex: 1 1 auto;
}

div.layer-catalog-placeholder {
    font-style: italic;
    color: var(--text-color-disabled);
}

div.layer-catalog-widget-entry {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
}

div.layer-catalog-widget-entry > .icon {
    flex: 0 0 1em;
}

div.layer-catalog-widget-entry > span.layer-catalog-widget-entry-iconspacer {
    flex: 0 0 1em;
}

div.layer-catalog-widget-entry > span.layer-catalog-widget-entry-contents {
    flex: 1 1 auto;
    font-size: small;
    padding: 0.25em;
    cursor: pointer;
    display: inline-block;
}

div.layer-catalog-widget-entry > span.layer-catalog-widget-entry-contents:hover {
    color: var(--color-active);
}

span.layer-catalog-widget-entry-service {
    display: inline-block;
    text-align: center;
    color: var(--text-color);
    background: var(--border-color);
    margin-right: 0.5em;
    border-radius: 0.25em;
    width: 6ch;
}
`, "",{"version":3,"sources":["webpack://./components/widgets/style/LayerCatalogWidget.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,qCAAqC;IACrC,sCAAsC;IACtC,gBAAgB;IAChB,oBAAoB;IACpB,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,iCAAiC;AACrC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,4CAA4C;AAChD;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,eAAe;IACf,qBAAqB;AACzB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,wBAAwB;IACxB,+BAA+B;IAC/B,mBAAmB;IACnB,qBAAqB;IACrB,UAAU;AACd","sourcesContent":["div.layer-catalog-widget {\n    display: flex;\n    flex-direction: column;\n}\n\ndiv.layer-catalog-widget-filter {\n    flex: 0 0 auto;\n    margin-bottom: -1px;\n}\n\ndiv.layer-catalog-widget-body {\n    padding: 0.25em;\n    border: 1px solid var(--border-color);\n    background-color: var(--list-bg-color);\n    overflow-y: auto;\n    margin-bottom: 0.5em;\n    flex: 1 1 auto;\n}\n\ndiv.layer-catalog-placeholder {\n    font-style: italic;\n    color: var(--text-color-disabled);\n}\n\ndiv.layer-catalog-widget-entry {\n    display: flex;\n    align-items: center;\n    border-bottom: 1px solid var(--border-color);\n}\n\ndiv.layer-catalog-widget-entry > .icon {\n    flex: 0 0 1em;\n}\n\ndiv.layer-catalog-widget-entry > span.layer-catalog-widget-entry-iconspacer {\n    flex: 0 0 1em;\n}\n\ndiv.layer-catalog-widget-entry > span.layer-catalog-widget-entry-contents {\n    flex: 1 1 auto;\n    font-size: small;\n    padding: 0.25em;\n    cursor: pointer;\n    display: inline-block;\n}\n\ndiv.layer-catalog-widget-entry > span.layer-catalog-widget-entry-contents:hover {\n    color: var(--color-active);\n}\n\nspan.layer-catalog-widget-entry-service {\n    display: inline-block;\n    text-align: center;\n    color: var(--text-color);\n    background: var(--border-color);\n    margin-right: 0.5em;\n    border-radius: 0.25em;\n    width: 6ch;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
