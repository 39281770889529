// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#TopBar {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 3.5em;
    color: var(--panel-text-color);
    background-color: var(--panel-bg-color);
    box-shadow: 0px 2px 4px rgba(136, 136, 136, 0.5);
    z-index: 102;
    display: flex;
    align-items: center;
}

#TopBar:not(.fullscreen) {
    backdrop-filter: blur(3px);
}

#TopBar img.logo {
    width: auto;
    height: 3.0em;
    vertical-align: middle;
}

#TopBar div.center-span {
    margin: 0 1em;
    flex: 1 1 auto;
    display: inline-flex;
    align-items: center;
}

#TopBar #FullScreenSwitcher {
    height: 3.5em;
}

#TopBar.fullscreen {
    background-color: transparent;
    box-shadow: none;
}

#TopBar.fullscreen img.logo,
#TopBar.fullscreen div.AppMenu .appmenu-label {
    display: none;
}

#TopBar div.AppMenu .appmenu-button {
    width: 20em;
    display: inline-block;
}

#TopBar div.AppMenu.appmenu-compact .appmenu-button,
#TopBar.mobile div.AppMenu .appmenu-button,
#TopBar.fullscreen div.AppMenu .appmenu-button {
    width: auto;
}

#TopBar.mobile div.AppMenu .appmenu-button {
    margin-right: 0;
}

`, "",{"version":3,"sources":["webpack://./qwc2/plugins/style/TopBar.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,OAAO;IACP,MAAM;IACN,QAAQ;IACR,aAAa;IACb,8BAA8B;IAC9B,uCAAuC;IACvC,gDAAgD;IAChD,YAAY;IACZ,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,cAAc;IACd,oBAAoB;IACpB,mBAAmB;AACvB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,6BAA6B;IAC7B,gBAAgB;AACpB;;AAEA;;IAEI,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,qBAAqB;AACzB;;AAEA;;;IAGI,WAAW;AACf;;AAEA;IACI,eAAe;AACnB","sourcesContent":["#TopBar {\n    position: absolute;\n    left: 0;\n    top: 0;\n    right: 0;\n    height: 3.5em;\n    color: var(--panel-text-color);\n    background-color: var(--panel-bg-color);\n    box-shadow: 0px 2px 4px rgba(136, 136, 136, 0.5);\n    z-index: 102;\n    display: flex;\n    align-items: center;\n}\n\n#TopBar:not(.fullscreen) {\n    backdrop-filter: blur(3px);\n}\n\n#TopBar img.logo {\n    width: auto;\n    height: 3.0em;\n    vertical-align: middle;\n}\n\n#TopBar div.center-span {\n    margin: 0 1em;\n    flex: 1 1 auto;\n    display: inline-flex;\n    align-items: center;\n}\n\n#TopBar #FullScreenSwitcher {\n    height: 3.5em;\n}\n\n#TopBar.fullscreen {\n    background-color: transparent;\n    box-shadow: none;\n}\n\n#TopBar.fullscreen img.logo,\n#TopBar.fullscreen div.AppMenu .appmenu-label {\n    display: none;\n}\n\n#TopBar div.AppMenu .appmenu-button {\n    width: 20em;\n    display: inline-block;\n}\n\n#TopBar div.AppMenu.appmenu-compact .appmenu-button,\n#TopBar.mobile div.AppMenu .appmenu-button,\n#TopBar.fullscreen div.AppMenu .appmenu-button {\n    width: auto;\n}\n\n#TopBar.mobile div.AppMenu .appmenu-button {\n    margin-right: 0;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
