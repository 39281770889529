// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.VectorLayerPicker > select {
    flex: 1 1 auto;
}
`, "",{"version":3,"sources":["webpack://./qwc2/components/widgets/style/VectorLayerPicker.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB","sourcesContent":["div.VectorLayerPicker > select {\n    flex: 1 1 auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
