// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.measure-body {
    margin-top: 0.25em;
    white-space: nowrap;
    overflow: visible;
    min-width: 17.5em;
    display: flex;
}

input.measure-result {
    flex: 1 1 auto;
    text-align: center;
    field-sizing: content;
}

span.measure-unit-label {
    padding: 0.25em;
    height: 2em;
    background-color: var(--input-bg-color);
    border: 1px solid var(--border-color);
    border-right-width: 0;
    display: flex;
    align-items: center;
}
`, "",{"version":3,"sources":["webpack://./qwc2/plugins/style/Measure.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;IACjB,iBAAiB;IACjB,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,uCAAuC;IACvC,qCAAqC;IACrC,qBAAqB;IACrB,aAAa;IACb,mBAAmB;AACvB","sourcesContent":["div.measure-body {\n    margin-top: 0.25em;\n    white-space: nowrap;\n    overflow: visible;\n    min-width: 17.5em;\n    display: flex;\n}\n\ninput.measure-result {\n    flex: 1 1 auto;\n    text-align: center;\n    field-sizing: content;\n}\n\nspan.measure-unit-label {\n    padding: 0.25em;\n    height: 2em;\n    background-color: var(--input-bg-color);\n    border: 1px solid var(--border-color);\n    border-right-width: 0;\n    display: flex;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
