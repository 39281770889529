// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.EditableSelect {
    position: relative;
}

div.editable-select-inputcontainer {
    width: 100%;    
}

div.editable-select-inputcontainer > span.icon-clear {
    width: 1.5em;
}

div.editable-select-dropdown {
    border: 1px solid var(--border-color);
    max-height: 8em;
    overflow-y: auto;
    background-color: var(--input-bg-color);
}

div.editable-select-dropdown > div {
    padding: 0.25em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

div.editable-select-dropdown > div:hover {
    background-color: var(--list-item-bg-color-hover);
    color: var(--list-item-text-color-hover);
}

div.editable-select-selopt {
    position: absolute;
    left: 1px;
    right: 1.5em;
    top: 1px;
    bottom: 1px;
    z-index: 1;
    pointer-events: none;
    display: flex;
    align-items: center;
    padding: 0 0.25em;
    background-color: var(--input-bg-color);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
`, "",{"version":3,"sources":["webpack://./qwc2/components/widgets/style/EditableSelect.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,qCAAqC;IACrC,eAAe;IACf,gBAAgB;IAChB,uCAAuC;AAC3C;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,gBAAgB;IAChB,uBAAuB;AAC3B;;AAEA;IACI,iDAAiD;IACjD,wCAAwC;AAC5C;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,YAAY;IACZ,QAAQ;IACR,WAAW;IACX,UAAU;IACV,oBAAoB;IACpB,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,uCAAuC;IACvC,mBAAmB;IACnB,gBAAgB;IAChB,uBAAuB;AAC3B","sourcesContent":["div.EditableSelect {\n    position: relative;\n}\n\ndiv.editable-select-inputcontainer {\n    width: 100%;    \n}\n\ndiv.editable-select-inputcontainer > span.icon-clear {\n    width: 1.5em;\n}\n\ndiv.editable-select-dropdown {\n    border: 1px solid var(--border-color);\n    max-height: 8em;\n    overflow-y: auto;\n    background-color: var(--input-bg-color);\n}\n\ndiv.editable-select-dropdown > div {\n    padding: 0.25em;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n\ndiv.editable-select-dropdown > div:hover {\n    background-color: var(--list-item-bg-color-hover);\n    color: var(--list-item-text-color-hover);\n}\n\ndiv.editable-select-selopt {\n    position: absolute;\n    left: 1px;\n    right: 1.5em;\n    top: 1px;\n    bottom: 1px;\n    z-index: 1;\n    pointer-events: none;\n    display: flex;\n    align-items: center;\n    padding: 0 0.25em;\n    background-color: var(--input-bg-color);\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
