// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table.AutoEditForm {
    width: 100%;
}

table.AutoEditForm td {
    padding: 0.25em;
    white-space: nowrap;
}

table.AutoEditForm td:first-child {
    max-width: 10em;
    text-overflow: ellipsis;
    overflow: hidden;
}

table.AutoEditForm td:nth-child(2) {
    width: 99%;
}

table.AutoEditForm select.editing-layer-select,
table.AutoEditForm select.editing-feature-select,
table.AutoEditForm span.input-frame select {
    width: 100%;
    border: 1px solid var(--border-color);
    background-color: var(--input-bg-color);
    padding: 0.25em;
}

table.AutoEditForm table.fields-table label {
    display: flex;
    height: 2em;
    align-items: center;
}

table.AutoEditForm label > input {
    margin-right: 0.5em;
}

table.AutoEditForm textarea {
    width: 100%;
    border: 1px solid var(--border-color);
}

table.AutoEditForm span.input-frame input {
    outline: none;
    background: none;
}

table.AutoEditForm span.input-frame {
    border: 1px solid var(--border-color);
    padding: 0.25em;
    background-color: var(--input-bg-color);
    display: flex;
    align-items: center;
    height: 2em;
}

table.AutoEditForm span.input-frame input,
table.AutoEditForm span.input-frame select {
    border: 0;
    flex: 1;
    min-width: 0;
    width: 0;
}
`, "",{"version":3,"sources":["webpack://./components/style/AutoEditForm.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,UAAU;AACd;;AAEA;;;IAGI,WAAW;IACX,qCAAqC;IACrC,uCAAuC;IACvC,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,qCAAqC;AACzC;;AAEA;IACI,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,qCAAqC;IACrC,eAAe;IACf,uCAAuC;IACvC,aAAa;IACb,mBAAmB;IACnB,WAAW;AACf;;AAEA;;IAEI,SAAS;IACT,OAAO;IACP,YAAY;IACZ,QAAQ;AACZ","sourcesContent":["table.AutoEditForm {\n    width: 100%;\n}\n\ntable.AutoEditForm td {\n    padding: 0.25em;\n    white-space: nowrap;\n}\n\ntable.AutoEditForm td:first-child {\n    max-width: 10em;\n    text-overflow: ellipsis;\n    overflow: hidden;\n}\n\ntable.AutoEditForm td:nth-child(2) {\n    width: 99%;\n}\n\ntable.AutoEditForm select.editing-layer-select,\ntable.AutoEditForm select.editing-feature-select,\ntable.AutoEditForm span.input-frame select {\n    width: 100%;\n    border: 1px solid var(--border-color);\n    background-color: var(--input-bg-color);\n    padding: 0.25em;\n}\n\ntable.AutoEditForm table.fields-table label {\n    display: flex;\n    height: 2em;\n    align-items: center;\n}\n\ntable.AutoEditForm label > input {\n    margin-right: 0.5em;\n}\n\ntable.AutoEditForm textarea {\n    width: 100%;\n    border: 1px solid var(--border-color);\n}\n\ntable.AutoEditForm span.input-frame input {\n    outline: none;\n    background: none;\n}\n\ntable.AutoEditForm span.input-frame {\n    border: 1px solid var(--border-color);\n    padding: 0.25em;\n    background-color: var(--input-bg-color);\n    display: flex;\n    align-items: center;\n    height: 2em;\n}\n\ntable.AutoEditForm span.input-frame input,\ntable.AutoEditForm span.input-frame select {\n    border: 0;\n    flex: 1;\n    min-width: 0;\n    width: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
