// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.TopBar {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 3.5em;
    color: var(--panel-text-color);
    background-color: var(--panel-bg-color);
    box-shadow: 0px 2px 4px rgba(136, 136, 136, 0.5);
    z-index: 102;
    display: flex;
    align-items: center;
}

div.TopBar:not(.fullscreen) {
    backdrop-filter: blur(3px);
}

div.TopBar img.topbar-logo {
    width: auto;
    height: 3.0em;
    vertical-align: middle;
}

div.TopBar div.topbar-center-span {
    margin: 0 1em;
    flex: 1 1 auto;
    display: inline-flex;
    align-items: center;
}

div.TopBar div.topbar-search-container {
    flex: 1 1 auto;
    max-width: 70ch;
}

div.TopBar.fullscreen {
    background-color: transparent;
    box-shadow: none;
}

div.TopBar.fullscreen img.topbar-logo,
div.TopBar.fullscreen span.appmenu-label {
    display: none;
}

div.TopBar.mobile div.appmenu-button {
    margin-right: 0;
}

`, "",{"version":3,"sources":["webpack://./plugins/style/TopBar.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,OAAO;IACP,MAAM;IACN,QAAQ;IACR,aAAa;IACb,8BAA8B;IAC9B,uCAAuC;IACvC,gDAAgD;IAChD,YAAY;IACZ,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,cAAc;IACd,oBAAoB;IACpB,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,6BAA6B;IAC7B,gBAAgB;AACpB;;AAEA;;IAEI,aAAa;AACjB;;AAEA;IACI,eAAe;AACnB","sourcesContent":["div.TopBar {\n    position: absolute;\n    left: 0;\n    top: 0;\n    right: 0;\n    height: 3.5em;\n    color: var(--panel-text-color);\n    background-color: var(--panel-bg-color);\n    box-shadow: 0px 2px 4px rgba(136, 136, 136, 0.5);\n    z-index: 102;\n    display: flex;\n    align-items: center;\n}\n\ndiv.TopBar:not(.fullscreen) {\n    backdrop-filter: blur(3px);\n}\n\ndiv.TopBar img.topbar-logo {\n    width: auto;\n    height: 3.0em;\n    vertical-align: middle;\n}\n\ndiv.TopBar div.topbar-center-span {\n    margin: 0 1em;\n    flex: 1 1 auto;\n    display: inline-flex;\n    align-items: center;\n}\n\ndiv.TopBar div.topbar-search-container {\n    flex: 1 1 auto;\n    max-width: 70ch;\n}\n\ndiv.TopBar.fullscreen {\n    background-color: transparent;\n    box-shadow: none;\n}\n\ndiv.TopBar.fullscreen img.topbar-logo,\ndiv.TopBar.fullscreen span.appmenu-label {\n    display: none;\n}\n\ndiv.TopBar.mobile div.appmenu-button {\n    margin-right: 0;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
