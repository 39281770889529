// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.accordeon-section {
    margin: 0.25em 0;
}

div.accordeon-section-header {
    display: flex;
    padding: 0.5em;
    background-color: var(--titlebar-bg-color);
    color: var(--titlebar-text-color);
    font-weight: bold;
}

div.accordeon-section-header > span:first-child {
    flex: 1 1 auto;
}

div.accordeon-section-body {
    padding: 0.25em;
    border-color: var(--border-color);
    border-style: solid;
    border-width: 0 1px 1px 1px;
}
`, "",{"version":3,"sources":["webpack://./components/widgets/style/AccordeonWidget.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,cAAc;IACd,0CAA0C;IAC1C,iCAAiC;IACjC,iBAAiB;AACrB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,iCAAiC;IACjC,mBAAmB;IACnB,2BAA2B;AAC/B","sourcesContent":["div.accordeon-section {\n    margin: 0.25em 0;\n}\n\ndiv.accordeon-section-header {\n    display: flex;\n    padding: 0.5em;\n    background-color: var(--titlebar-bg-color);\n    color: var(--titlebar-text-color);\n    font-weight: bold;\n}\n\ndiv.accordeon-section-header > span:first-child {\n    flex: 1 1 auto;\n}\n\ndiv.accordeon-section-body {\n    padding: 0.25em;\n    border-color: var(--border-color);\n    border-style: solid;\n    border-width: 0 1px 1px 1px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
