// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#MapCompare {
    position: fixed;
    background-color: var(--map-button-bg-color);
    width: 0.25em;
    height: 100%;
    margin-left: -0.125em;
    top: 0;
    cursor: ew-resize;
}

#MapCompare span.map-compare-handle {
    position: absolute;
    top: calc(50% - 3em);
    height: 3em;
    width: 3em;
    left: -1.375em;
    background-color: var(--map-button-bg-color);
    display: flex;
    align-items: center;
    border-radius: 1.5em;
}

#MapCompare span.map-compare-handle-icon {
    flex: 0 0 1.5em;
    color: var(--map-button-text-color);
    text-align: center;
}
`, "",{"version":3,"sources":["webpack://./plugins/style/MapCompare.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,4CAA4C;IAC5C,aAAa;IACb,YAAY;IACZ,qBAAqB;IACrB,MAAM;IACN,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,oBAAoB;IACpB,WAAW;IACX,UAAU;IACV,cAAc;IACd,4CAA4C;IAC5C,aAAa;IACb,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,eAAe;IACf,mCAAmC;IACnC,kBAAkB;AACtB","sourcesContent":["#MapCompare {\n    position: fixed;\n    background-color: var(--map-button-bg-color);\n    width: 0.25em;\n    height: 100%;\n    margin-left: -0.125em;\n    top: 0;\n    cursor: ew-resize;\n}\n\n#MapCompare span.map-compare-handle {\n    position: absolute;\n    top: calc(50% - 3em);\n    height: 3em;\n    width: 3em;\n    left: -1.375em;\n    background-color: var(--map-button-bg-color);\n    display: flex;\n    align-items: center;\n    border-radius: 1.5em;\n}\n\n#MapCompare span.map-compare-handle-icon {\n    flex: 0 0 1.5em;\n    color: var(--map-button-text-color);\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
